@import "../../../global.less";

@form-label-color: #9A9A9A;

.custom-modal {
    .ant-modal-header {
        border-bottom: none;
        border-radius: 16px ;
        padding: 20px;
    }

    .ant-modal-footer {
        border-top: none;
    }

    .ant-modal-content {
        border-radius: 16px;

        .ant-modal-body {
            padding: 10px 0;
        }
    }
}

.modal-layout {
    margin-left: 50px;
    margin-right: 50px;
}

.modal-content {
    .modal-top-left {
        width: 125px;
        align-items: flex-start;
        justify-content: center;

        .modal-logo {
            width: 45px;
            height: 45px;
        }
    }

    .modal-top-right {
        width: 100%;
        justify-content: flex-end;
        align-items: center;
    }
}

.form-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: @form-label-color;
}

.form-input {
    background-color: @form-input-bg-color;
    border-radius: 12px;
    padding: 11px;
   
    outline: none;
}

.ant-input {
    font-size: 17px;
    line-height: 21px;
    font-weight: 600;
    color: @av-dark-black;
}

.form-button {
    background-color: @av-red;
    border-color: @av-red;
    color: @av-white;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.025em;
    height: 40px;

    &:hover, &:focus, &:active {
        color: @av-white;
    }
}

.error-box {
    color: @av-white;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    margin-bottom: 20px;
    align-items: center;
    border-radius: 7px;
}